import EntryContext from "../context/entryContext";
import { useContext } from "react";

function Controls() {
  const { exportEntries, flushEntries } = useContext(EntryContext);
  return (
    <div className="container">
      <button
        onClick={exportEntries}
        type="button"
        className="text-white bg-slate border border-slate-300 focus:outline-none hover:bg-slate-100 focus:ring-4 focus:ring-slate-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-slate-700 dark:text-white dark:border-slate-600 dark:hover:bg-slate-800 dark:hover:border-slate-700 dark:focus:ring-slate-600"
      >
        Export All Entries
      </button>
      <button
        onClick={flushEntries}
        type="button"
        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
      >
        Flush All Entries
      </button>
    </div>
  );
}
export default Controls;
