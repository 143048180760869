import DisplayTable from "./components/DisplayTable";
import Card from "./components/shared/Card.jsx";
import SwipeForm from "./components/SwipeForm";
import Controls from "./components/Controls.jsx";
import { EntryProvider } from "./context/entryContext.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <EntryProvider>
      <div className="app">
        <Card>
        <SwipeForm />
        <DisplayTable />
        <Controls />
        </Card>
      </div>
      <ToastContainer hideProgressBar={true}/>
    </EntryProvider>
  );
}

export default App;
