import EntryContext from "../context/entryContext";
import { Table, Space } from "antd";
import { useContext } from "react";
import { FaTrash } from "react-icons/fa";

function DisplayTable() {
  const { entries, deleteEntry } = useContext(EntryContext);
  if (!entries || entries.length === 0) {
    return (
      <div className="container">
        <p>No Entries For Today Yet!</p>
      </div>
    );
  }
  const columns = [
    {
      title: "Date & Time",
      dataIndex: "time_date",
      key: "time_date",
    },
    {
      title: "Onecard ID",
      dataIndex: "onecard_id",
      key: "onecard_id",
    },
    {
      title: "Student Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Delete?",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <FaTrash onClick={() => deleteEntry(record.id)} />
        </Space>
      ),
    },
  ];
  return (
    <div className="container">
      <Table columns={columns} dataSource={entries} rowKey="id" />
    </div>
  );
}

export default DisplayTable;
