
import { useContext } from 'react';
import EntryContext from '../context/entryContext.js';

function SwipeForm() {
 const {submitManualEntry, submitSwipe } = useContext(EntryContext)
  return (
    <div className='container rounded-lg m-3 justify-self-auto  text-white uppercase'>
      <form  id='swiped-entry' onSubmit={submitSwipe}>
        <h2>Swipe your OneCard into this field</h2>
        <div className='grid gap-6 mb-6 md:grid-cols-1'>
          <input
          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
            type='text'
            name='swipeInput'
            id='swipeInput'
            placeholder='Swipe Here'
            onBlur={submitSwipe}
          />
        </div>
      </form>
      <form id='manual-entry'>
        <div className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>or enter your OneCard ID and Name </div>
        <div className='flex flex-row gap-2 '>
            <input
            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              type='text'
              placeholder='Onecard ID'
              name='onecardField'
              id='onecardField'
            />
            <input
            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              type='text'
              placeholder='Last/First Name'
              name='nameField'
              id='nameField'
            />
            <button
              className='mt-2 text-white-900 bg-slate border border-slate-300 focus:outline-none hover:bg-slate-100 focus:ring-4 focus:ring-slate-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-slate-800 dark:text-white dark:border-slate-600 dark:hover:bg-slate-700 dark:hover:border-slate-600 dark:focus:ring-slate-700'
              id='swipeSubmitBtn'
              onClick={submitManualEntry}
            >
              Submit
            </button>
        </div>
      </form>
    </div>
  );
}

export default SwipeForm;
