import { createContext, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import moment from "moment";
const EntryContext = createContext()

export const EntryProvider = ({ children }) => {
    const [entries, setEntries] = useState([])
    useEffect(() => { fetchEntries() }, []);
    const fetchEntries = async () => {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + "api/", { mode: "cors" })
        const data = await response.json()
        console.log(data)
        data.map((entry) => { 
            entry.time_date = moment(entry.time_date).format('MM-DD-YYYY h:mma')
            return entry.key = entry.id 
        })
        setEntries(data)
    }
    // submit swiped entry
    const submitSwipe = async (e) => {
        e.preventDefault();
        const swipe_data = String(document.getElementById('swipeInput').value)
        if (swipe_data.length === 0) { return toast('You must swipe your one card to submit this field.') }
        const data = {
            swipe_data
        };
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'api/submitSwipe', {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            method: 'POST',
            mode: 'cors',
        });
        if (response.status !== 201) { return toast(await response.text(), { autoClose: 5000 }) }
        const jsonData = await response.json()
        const newEntry = { key: jsonData.id, id: jsonData.id, onecard_id: jsonData.onecard_id, name: jsonData.name, time_date: moment(jsonData.createdAt).format('MM-DD-YY h:mm a') }
        setEntries([...entries, newEntry])
        document.getElementById('swipeInput').value = ''
        toast('Your entry was submitted succesfully!')
    }

    //submit manual onecard/name combo
    const submitManualEntry = async (e) => {
        e.preventDefault();
        const data = {
            onecard_id: document.getElementById('onecardField').value,
            name: document.getElementById('nameField').value,
        };
        if (data.onecard_id === '' || data.name === '') { return toast('You must include both fields to record your attendance') }
        if (data.onecard_id.length !== 7) { return toast('OneCard ID numbers are always 7 digits') }
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'api/submitManual', {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            method: 'POST',
            mode: 'cors',
        });
        if (response.status !== 201) { return toast(await response.text(), { autoClose: 5000 }) }
        const jsonData = await response.json()
        const newEntry = { key: jsonData.id, id: jsonData.id, onecard_id: jsonData.onecard_id, name: jsonData.name, time_date: moment(jsonData.createdAt).format('MM-DD-YY h:mm a') }
        setEntries([...entries, newEntry])
        document.getElementById('onecardField').value = ''
        document.getElementById('nameField').value = ''

        toast('Your entry was submitted succesfully!')
    }
    //TODO fix delete to remove from collection on pocketbase
    //delete entries
    const deleteEntry = async (id) => {
        if (window.confirm('Are you sure you want to remove this attendence item?' + id)) {
            fetch(process.env.REACT_APP_BACKEND_URL + 'api/delete', {
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ "id": id }),
                method: 'DELETE',
                mode: 'cors'
            })
            setEntries(entries.filter((entry) => entry.id !== id))
            toast('Deleted one entry succesfully!')
        }
    }
    const exportEntries = async () => {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + "api/export", { mode: 'cors' })
        const blobData = await response.blob()
        const url = window.URL.createObjectURL(blobData);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = "attendance.csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast('Your file is ready to be downloaded')
    }
    // deletes all entries
    const flushEntries = async () => {
        if (window.confirm("This will delete all entries and there is no going back. Make sure you export first!")) {
            fetch(process.env.REACT_APP_BACKEND_URL + 'api/flushEntries', { method: 'DELETE' })
            setEntries([])
        }
    }
    return <EntryContext.Provider value={{ entries, submitSwipe, submitManualEntry, deleteEntry, exportEntries, flushEntries }}>
        {children}
    </EntryContext.Provider>
}

export default EntryContext